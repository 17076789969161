import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Praxis - Dr. Kathrin Kostka" />
    <div className="greenContainer">
      <ul>
        <li><Link to="/praxis">Unsere Praxis</Link></li>
        <li><Link to="/praxis/dr-burkhard-schubert">Dr. Burkhard Schubert</Link></li>
        <li className="active"><Link to="/praxis/dr-kathrin-kostka">Dr. Kathrin Kostka</Link></li>
      </ul>
    </div>
    <Section dark>
      <Row noGutters>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-image" />
        </Col>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-content right">
            <Headline light>Dr. Kathrin Kostka</Headline>
            <p>
              <ul>
                <li>Fachärztin für Anästhesie</li>
                <li>Behandlungsschwerpunkte: Schmerztherapie/ Ernährungsmedizin/ Ästhetik</li>
                <li>über 20 jährige Facharzttätigkeit mit Weiterbildung in komplementären Therapieformen und ästhetischer Medizin</li>
              </ul>
            </p>
          </div>
        </Col>
      </Row>
    </Section>
    <Section>
      <Container>
        <Headline>Zusatzbezeichnungen und Zertifizierungen</Headline>
        <Row>
          <Col xs={12}>
            <ul>
              <li>Ernährungsmedizin</li>
              <li>Homöopathie</li>
              <li>Mesotherapie</li>
              <li>Ästhetik</li>
              <li>Akupunktur</li>
              <li>Schmerztherapie</li>
              <li>Stosswellentherapie</li>
              <li>Fachkunde Rettungsdienst</li>
              <li>Fachkunde Strahlenschutz  Röntgendiagnostik</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
